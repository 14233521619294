export enum FormStatus {
  InProgress = 'in_progress',
  Error = 'error',
  Success = 'success',
  Submitting = 'submitting',
}

export enum FormMode {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
}
